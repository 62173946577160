import { useAppContext } from "@pagegrowler_nextjs";

export const useHome = (props) => {

    const { content, ...rest } = props;

    const menu = rest?.menu ?? {};
    const preview = rest?.preview ?? false;
    const isPreview = rest?.preview ?? false;

    const pg = useAppContext();
    const Pagegrowler = pg?.pagegrowler;
    Pagegrowler?.setWidgetsLayout(rest?.widgetList);

    const metaRefresh = rest?.meta?.filter((metatags) => metatags.meta == "refresh")

    const contentNotes = [];

    const title = rest?.landing?.title ?? "";

    const bucketsHeader = (rest?.properties?.bucketsHeader)
        ? rest.properties.bucketsHeader
        : { opinion: [], liveblogs: [], ultimomento: [], urgent: [] };

    const dataAnalitics = {
        sections: "home",
        pageType: "Home",
        nsSite: "clarin",
        name: "home.inicio",
        publishedDate: rest?.landing?.modificatedAt ?? new Date(),
    };

    const dataMeter = {
        pageType: "portada",
        section: "home",
        paywall: "free",
        category: "home"
    };

    try {
        for (let index = 0; index < content?.boxes?.length; index++) {

            const arrayModules = content?.boxes?.[index]?.innerBoxes?.[0]?.subItems;
            const arrayNotes = arrayModules?.[0]?.params?.content ?? [];

            arrayNotes?.forEach((content) => {
                contentNotes.push(content);
            });

            arrayModules?.forEach((module, sub_index) => {
                switch (module.template) {
                    case "Container6Pisos":
                    case "Container7Pisos":
                    case "Container5PisosExtra":
                        content.boxes[index].innerBoxes[0].subItems[sub_index].params[
                            "datalatest"
                        ] = bucketsHeader?.ultimomento;
                        break;
                    case "Container3PisosOpinion":
                        content.boxes[index].innerBoxes[0].subItems[sub_index].params[
                            "dataopinion"
                        ] = bucketsHeader?.opinion;
                        break;
                    case "PriceRural":
                        content.boxes[index].innerBoxes[0].subItems[sub_index].params[
                            "priceRural"
                        ] = rest?.properties?.priceRural ?? {};
                        break;
                    case "Container2PisosBanner":
                        content.boxes[index].innerBoxes[0].subItems[sub_index].params[
                            "newsCrossContent"
                        ] = rest?.properties?.crossContent ?? [];
                        break;
                    case "RevistaElle":
                        content.boxes[index].innerBoxes[0].subItems[sub_index].params[
                            "content"
                        ] = rest?.properties?.elleMagazines?.map((news) => ({
                            ...news?.value,
                            imageOriginUrl: 'https://elle.clarin.com/images',
                            section: news?.value?.sections ?? [],
                            images: news?.value?.related?.images?.[0]?.value?.clippings ?? [],
                            url: `https://elle.clarin.com${news?.value?.url ?? ''}`,
                            urlTarget: '_blank',
                            title: news?.title ? news.title : news?.value?.title,
                            subtitle: news?.subtitle ? news.subtitle : news?.value?.subtitle
                        }))
                        break;
                    case "ContainerJokes":
                        content.boxes[index].innerBoxes[0].subItems[sub_index].params[
                            "content"
                        ] = (!rest?.properties?.jokes?.error && rest?.properties?.jokes?.content)
                                ? rest.properties.jokes.content : [];
                        break;
                    case "MostViewed":
                        content.boxes[index].innerBoxes[0].subItems[sub_index].params[
                            "content"
                        ] = rest?.properties?.mostReadNewsWidget ?? []
                        break;
                    default:
                        break;
                }
            });
        }
    } catch (error) {
        console.error("error setting boxes");
    }

    return {
        ...rest,
        menu,
        preview,
        isPreview,
        Pagegrowler,
        formatContent: {
            content: contentNotes,
            dataAnalitics,
            dataMeter,
            bucketsHeader,
            adsPath: "home",
            metaRefresh: {
                status: metaRefresh?.[0]?.value != "0",
                timer: metaRefresh?.[0]?.value && !isNaN(metaRefresh?.[0]?.value)
                    ? metaRefresh[0].value : 300
            },
            dataMetaTagsSubhome: {
                title,
                section: "",
                url: ""
            },
            dataHeader: {
                urgent: bucketsHeader?.urgent,
                liveblogs: bucketsHeader?.liveblogs,
                newsToday: menu?.noticiashoy?.item?.items,
                menu,
                isHome: true
            }
        },
    }
}